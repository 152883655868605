import React, { useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
} from '@fortawesome/free-solid-svg-icons'

import { Menu, Image, Drawer, message } from 'antd';

import { useIsLoggedIn, usePlateformConfig } from '../../reduxhooks/ReduxHooks';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { USER_API_LOGOUT } from '../../config';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { isLogged } from '../../reducers/slices/isLoggedIn.slice';


import logoDesktop from '../../assets/medias/logo/logoDesktop.png';
import logoMobile from '../../assets/medias/logo/logoMobile.png';

import socket from '../../socket/Socket';

import MenuItems from './MenuItems';

import { API_GET_MEDIAS } from '../../config';

export default function MenuLandingPagePrin() {

  const [messageApi, contextHolderMessage] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { menuConnected, menuNotConnected } = MenuItems();

  const { t } = useTranslation();

  const [openMenu, setOpenMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState('home')

  const loggedIn = useIsLoggedIn();
  const plateformConfig = usePlateformConfig();

  const key = 'updatable';

  const [items, setItems] = useState([]);

  useEffect(() => {

    if(loggedIn.logged) {
      setItems(menuConnected)
    } else {
      setItems(menuNotConnected)
    }

  }, [loggedIn.logged]) 

  const onClickMenu = (e) => {
   
    const selectedItem = items.find(items => items.key === e.key);
   

    setSelectedKey(e.key);
    setOpenMenu(false);
    if (selectedItem) {
      const { path, type } = selectedItem;

      if (type === 'link') {
        navigate(path);
      }

      if (type === 'anchor') {
        const anchorId = selectedItem.path.slice(1);
        const targetElement = document.getElementById(anchorId);
     

        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

        navigate('/');
      }
      if (type === 'function' && path === 'Singout') {
        if (loggedIn.logged) {

          axios.post(`${USER_API_LOGOUT}`, { withCredentials: true },
          ).then(response => {
            
            messageApi.success({ content: t('singout.successSingout'), key, duration: 1 })
              dispatch(isLogged({
                logged: false,
                user: null,
                roles: null
              }));
              socket.disconnect();
              socket.connect();
              navigate('/', { replace: true });
          
          }).catch(error => {
    
            messageApi.success({ content: t('singout.successSingout'), key, duration: 1 }).then(() => {
              dispatch(isLogged({
                logged: false,
                user: null,
                roles: null
              }));
              socket.disconnect();
              socket.connect();
              navigate('/', { replace: true });
          });

          });
        } else {
          navigate('/Singin', { replace: true });
        }
      }
    }
  }

  function MainMenu({ isInLine = false }) {
    return (
      <Menu onClick={onClickMenu} selectedKeys={[selectedKey]} mode={isInLine ? "inline" : "horizontal"} items={items} style={!isInLine ? { display: 'flex', justifyContent: 'flex-end', height: '65px', zIndex : 10 } : null} />
    )
  }

  const LogoMainDesktop = () => {
    return (
      <div className='container-logo'>
        <NavLink to='/' className='logo me-auto' >
          <Image src={plateformConfig.logoDesktop ? `${API_GET_MEDIAS}`+'logo/logoDesktop/'+plateformConfig.logoDesktop : logoDesktop} alt='Logo' preview={false} className='logo-picture' />
        </NavLink >
      </div>
    )
  }
  const LogoMainMobile = () => {
    return (
      <div className='container-logo'>
        <NavLink to='/' className='logo me-auto' >
          <Image src={plateformConfig.logoMobile ? `${API_GET_MEDIAS}`+'logo/logoMobile/'+plateformConfig.logoMobile : logoMobile} alt='Logo' preview={false} className='logo-picture' />
        </NavLink >
      </div>
    )
  }
  return (
    <div className='container-main-landing d-flex align-items-center'>
      {contextHolderMessage}
      <LogoMainDesktop />
      <div className='container-menu'>
        <div className='btn-menu'>
          <FontAwesomeIcon icon={faBars} onClick={() => setOpenMenu(true)} />
        </div>
        <div className='header-menu-menu'>
          <MainMenu />
        </div>
        <Drawer
          title={<LogoMainMobile />}
          placement='top'
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          className='menu-drawer'
        >
          <MainMenu isInLine />
        </Drawer>
      </div>

    </div>
  )
}
