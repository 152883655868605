export const USER_API_GET_WEBSITE_CONFIG = `api/user/websiteConfig`;
export const USER_API_GET_SINGUP_CONFIG = `api/user/singupConfig`;

export const USER_API_SINGIN = `api/auth/singin`;
export const USER_API_SINGUP = `api/auth/singup`;

export const USER_API_VERIFY_MAIL_IN_DB = `api/auth/verifyMailInDb`;
export const USER_API_VERIFY_ACCOUNT = `api/auth/verifyAccount`;
export const USER_API_FORGET_PASSWORD = 'api/auth/forgetPassword';


export const USER_API_LOGOUT = `api/auth/logout`;
export const USER_API_ISLOGGEDIN = `api/auth/getIsLoggedIn`;

export const USER_API_VERIFY_TOKEN_RESET_PASSWORD = `api/auth/verifytokenResetPassword`;
export const USER_API_RESET_PASSWORD = `api/auth/resetPassword`;

export const API_USER_FILEPV = 'api/user/filePv';

export const API_USER_GETCAROUSELELEMENTS = 'api/user/getCarouselElements'

export const API_USER_GET_ABSTRACT = `api/user/getAbstract`;
export const API_USER_NEW_ABSTRACT = `api/user/newAbstract`;
export const API_USER_DELETE_ABSTRACT = `api/user/deleteAbstract`;
export const API_USER_EDIT_ABSTRACT = `api/user/editAbstract`;
export const API_USER_GET_ABSTRACT_REMARQUE_BYID = `api/user/getAbstractRemarqueById`;
export const API_USER_POST_EPOSTER = `api/user/postEposter`;
export const API_USER_GET_CARD = `api/user/getCard`;

export const SUPERADMIN_API_GETSERVERDATA = `api/superadmin/getServerData`;
export const SUPERADMIN_API_SETTHEME = `api/superadmin/setTheme`;

export const SUPERADMIN_API_SETLOGO = `api/superadmin/setLogo`;
export const SUPERADMIN_API_SETPICTURE = `api/superadmin/setPicture`;
export const SUPERADMIN_API_SETPICTURECAROUSEL = `api/superadmin/setPictureCarousel`;

export const SUPERADMIN_API_GETALLCAROUSEL = `api/superadmin/getAllCarousel`;
export const SUPERADMIN_API_CHANGEETATELEMENTCAROUSEL = 'api/superadmin/changeEtatElementCarousel'


export const SUPERADMIN_API_SETTEXTPRESIDENT = `api/superadmin/setTextPresident`;
export const SUPERADMIN_API_REMOVETEXTPRESIDENT = `api/superadmin/removeTextPresident`;
export const SUPERADMIN_API_SETSOCIALMEDIAS = 'api/superadmin/setSocialMedias';
export const SUPERADMIN_API_SETCONTACTINFOS = 'api/superadmin/setContactInfos';
export const SUPERADMIN_API_STESPONSORSCAT = 'api/superadmin/setSponsorsCat';
export const SUPERADMIN_API_STESPONSORS = 'api/superadmin/setSponsors';
export const SUPERADMIN_API_STETHEMES = 'api/superadmin/setThemesLanding';
export const SUPERADMIN_API_STETEAMCAT = 'api/superadmin/setTeamCat';
export const SUPERADMIN_API_SETTEAM = 'api/superadmin/setTeam';
export const SUPERADMIN_API_SETINFOSBAR = 'api/superadmin/setInfosBar';
export const SUPERADMIN_API_SETPACK = 'api/superadmin/setPack';
export const SUPERADMIN_API_SETPACKCAT = 'api/superadmin/setPackCat';
export const SUPERADMIN_API_SETPAYEMENTLIST = 'api/superadmin/setPayementList';
export const SUPERADMIN_API_STEPAYEMENTCAT = 'api/superadmin/setPayementCat';

export const SUPERADMIN_API_SETSINGUPCONFIG = 'api/superadmin/setSingupConfig';

export const API_ADMIN_GET_ALL_USERS = 'api/admin/getAllUsers';
export const API_ADMIN_CHANGE_ACCOUNT_TYPE='api/admin/changeAccountType';
export const API_ADMIN_CHANGE_ACCOUNT_VERIFIED='api/admin/changeAccountVerified';
export const API_ADMIN_CHANGE_ACCOUNT_PAYEMENT='api/admin/changeAccountPayement';

export const API_ADMIN_GET_ALL_USERS_PRESENCE = 'api/admin/getAllUsersPresence';
export const API_ADMIN_MAKE_USER_PRESENCE = 'api/admin/makeUserPresence';
export const API_ADMIN_DELETE_USER_PRESENCE = 'api/admin/deleteUserPresence';


export const API_ADMIN_GET_ALL_ABSTRACTS = 'api/admin/getAllAbstract';
export const API_ADMIN_EDIT_STATE_ABSTRACT = 'api/admin/editStateAbstract';
export const API_ADMIN_ABSTRACT_ADD_COMMENT = 'api/admin/addCommentAbstract';

export const ADMIN_API_SINGUP = `api/admin/singup`;
export const API_GET_MEDIAS = `medias-mep/`;

export const SOCKET_URL = `/sio`;


