


import React, { useState, useEffect } from 'react';
import { FloatButton, Layout } from 'antd';

import { Outlet } from "react-router-dom"


import MenuLandingPagePrin from '../components/menu/MenuLandingPagePrin';

import { useSelector } from 'react-redux';
import { statesiteConfig } from '../reducers/slices/siteConfig.slice';
import InfosBars from '../components/infosBars/InfosBars';
import { usePlateformConfig } from '../reduxhooks/ReduxHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';



export default function AppLayout({ children, logoUrl }) {

  const plateformConfig = usePlateformConfig();

  const { Header, Content, Footer } = Layout;

  const configValue = useSelector(statesiteConfig);

  const year = new Date().getFullYear()

  const { i18n } = useTranslation();

  const [scrollInfoTop, setScrollInfoTop] = useState(true);
  const [languageOpen, setLanguageOpen] = useState(false);
  const firstHeaderHeight = 40;


  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setScrollInfoTop(scrollTop);
  };

  return (



    <Layout style={{ display: 'flex' }}>

      {plateformConfig.infosBar &&
        <Header
          className='header-infos'
          style={{ marginTop: scrollInfoTop < 200 ? 0 : -39, backgroundColor: configValue.infosBarBkColor }}
        >
          <InfosBars />
        </Header>
      }
      <Header
        className="header-menu"
        style={{ marginTop: scrollInfoTop < 200 ? `${firstHeaderHeight}px` : 1 }}
      >
        <MenuLandingPagePrin />

      </Header>

      <Content>
{/*       <FloatButton.Group
          open={languageOpen}
          trigger="click"
          style={{ right: 24, bottom : 90 }}
          icon={<FontAwesomeIcon icon={['fas', 'earth-africa']} />}
          onClick={() => setLanguageOpen(!languageOpen)}
        >
          <FloatButton onClick={() => i18n.changeLanguage('fr')}/>
          <FloatButton onClick={() => i18n.changeLanguage('en')}/>
        </FloatButton.Group>
     */}

        <Outlet />

      </Content>
      <Footer >
        &copy; Copyright Medical Events {year} . All Rights Reserved. Designed by Ah Mo
      </Footer>
    </Layout>


  )
}
