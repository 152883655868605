import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux';

import { items } from '../reducers/slices/siteConfig.slice';
import { itemsPlateform } from '../reducers/slices/plateformConfig.slice';
import { itemsSingupConfig } from '../reducers/slices/singupConfig.slice';
import { isLogged } from '../reducers/slices/isLoggedIn.slice';
import { isSocketUMLoadingItems } from '../reducers/slices/isSocketUMLoading.slice';
import { isSocketPMLoadingItems } from '../reducers/slices/isSocketPMLoading.slice';
import { itemsCarousel } from '../reducers/slices/carouselConfig.slice';

import socket from './Socket';
import { notification } from 'antd';
import { useIsLoggedIn } from '../reduxhooks/ReduxHooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_USER_GETCAROUSELELEMENTS } from '../config';

socket.connect();
window.socketUser = socket;

export default function SocketInterceptor({ children }) {
    const { t } = useTranslation();
    const userFound = useIsLoggedIn();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notificationApi, contextHolderNotification] = notification.useNotification();

    useEffect(() => {
        const handleThemeSet = (data) => {
            dispatch(items(data));
        };

        const handleLogoDesktop = (data) => {
            const updateLogoDesktop = {
                logoDesktop: data
            };
            dispatch(itemsPlateform(updateLogoDesktop));
        };

        const handleLogoMobile = (data) => {
            const updateLogoMobile = {
                logoMobile: data
            };
            dispatch(itemsPlateform(updateLogoMobile));
        };

        const handlePictureDesktop = (data) => {
            const updatePictureDesktop = {
                pictureDesktop: data
            };
            dispatch(itemsPlateform(updatePictureDesktop));
        };

        const handlePictureMobile = (data) => {
            const updatePictureMobile = {
                pictureMobile: data
            };
            dispatch(itemsPlateform(updatePictureMobile));
        };

        const handleTextPresident = (data) => {
            const updateTextPresident = {
                textPresident: data.textPresident,
                textPresidentSource: data.textPresidentSource
            };
            dispatch(itemsPlateform(updateTextPresident));
        };

        const handleSocialMedias = (data) => {
            const updateSocialMedias = {
                socialMedias: data
            };
            dispatch(itemsPlateform(updateSocialMedias));
        };

        const handleContactInfos = (data) => {
            const updateContactInfos = {
                googleMapsLink: data.googleMapsLink,
                adress: data.adress,
                mailContact: data.mailContact,
                phoneContact: data.phoneContact
            };
            dispatch(itemsPlateform(updateContactInfos));
        };

        const handleSponsorsCat = (data) => {
            dispatch(itemsPlateform(data));
        };

        const handleSponsors = (data) => {
            const updatedSponsor = {
                sponsors: data
            };
            dispatch(itemsPlateform(updatedSponsor));
        };

        const handleThemesLanding = (data) => {
            dispatch(itemsPlateform(data));
        };

        const handleTeamCat = (data) => {
            dispatch(itemsPlateform(data));
        };

        const handleTeam = (data) => {
            const teamUpdate = {
                team: data
            };
            dispatch(itemsPlateform(teamUpdate));
        };

        const handleInfosBar = (data) => {
            dispatch(itemsPlateform(data));
        };

        const handlePackList = (data) => {
            dispatch(itemsSingupConfig(data));
        };

        const handlePackListCat = (data) => {
            dispatch(itemsSingupConfig(data));
        };

        const handlePayementList = (data) => {
            dispatch(itemsSingupConfig(data));
        };

        const handlePayementCatList = (data) => {
            dispatch(itemsSingupConfig(data));
        };

        const handleSingupConfig = (data) => {
            dispatch(itemsSingupConfig(data));
        };

        const handleAccountTypeChanged = (data) => {
            if (userFound.user && userFound.user.id === data.userId) {
                const values = {
                    roles: data.accountType
                };
                dispatch(isLogged(values));
            }
        };

        const handleAccountTPayementChanged = (data) => {
            if (userFound.user && userFound.user.id === data.userId) {
                const values = {
                    user: {
                        ...userFound.user,
                        verified: data.verified
                    }
                };
                dispatch(isLogged(values));

                if (!data.verified) {
                    notificationApi.error({ message: t('usersManager.payementRefused'), placement: 'bottomRight' });
                } else {
                    notificationApi.success({ message: t('usersManager.payementAccepted'), placement: 'bottomRight' });
                }
            }
        };

        const handleFilePv = (data) => {
            if (userFound.user && userFound.user.id === data.userId) {
                const values = {
                    user: {
                        ...userFound.user,
                        fileVerification: data.fileName
                    }
                };
                dispatch(isLogged(values));
            }
        };

        const handleUsersManager = (data) => {
            const values = {
                isSocketLoading: true,
                msg: data.msg
            };
            dispatch(isSocketUMLoadingItems(values));
        };

        const handlePresenceManager = (data) => {
            console.log("data", data);
            const values = {
                isSocketLoading: true,
                msg: data.msg
            };
            dispatch(isSocketPMLoadingItems(values));
        };


        const handleAccountDesactived = (data) => {
            if (userFound.user && userFound.user.id === data.userId) {
                dispatch(isLogged({
                    logged: false,
                    user: null,
                    roles: null
                }));
                socket.disconnect();
                socket.connect();
                navigate('/Singin', { replace: true });
            }
        };

        const handleCarouseChanged = () => {
            axios.get(`${API_USER_GETCAROUSELELEMENTS}`)
            .then(response => { dispatch(itemsCarousel(response.data.carouselItems))})
            .catch(error => {})
        }

        socket.on('themeSet', handleThemeSet);
        socket.on('logoDesktop', handleLogoDesktop);
        socket.on('logoMobile', handleLogoMobile);
        socket.on('pictureDesktop', handlePictureDesktop);
        socket.on('pictureMobile', handlePictureMobile);
        socket.on('textPresident', handleTextPresident);
        socket.on('socialMedias', handleSocialMedias);
        socket.on('contactInfos', handleContactInfos);
        socket.on('sponsorsCat', handleSponsorsCat);
        socket.on('sponsors', handleSponsors);
        socket.on('themesLanding', handleThemesLanding);
        socket.on('teamCat', handleTeamCat);
        socket.on('team', handleTeam);
        socket.on('infosBar', handleInfosBar);
        socket.on('packList', handlePackList);
        socket.on('packCat', handlePackListCat);
        socket.on('payementList', handlePayementList);
        socket.on('payementListCat', handlePayementCatList);
        socket.on('singupConfig', handleSingupConfig);
        socket.on('accountTypeChanged', handleAccountTypeChanged);
        socket.on('accountTPayementChanged', handleAccountTPayementChanged);
        socket.on('filePv', handleFilePv);
        socket.on('usersManager', handleUsersManager);
        socket.on('presenceManager', handlePresenceManager);
        socket.on('accountDesactived', handleAccountDesactived);
        socket.on('carouselChanged', handleCarouseChanged);

        return () => {
            socket.off('themeSet', handleThemeSet);
            socket.off('logoDesktop', handleLogoDesktop);
            socket.off('logoMobile', handleLogoMobile);
            socket.off('pictureDesktop', handlePictureDesktop);
            socket.off('pictureMobile', handlePictureMobile);
            socket.off('textPresident', handleTextPresident);
            socket.off('socialMedias', handleSocialMedias);
            socket.off('contactInfos', handleContactInfos);
            socket.off('sponsorsCat', handleSponsorsCat);
            socket.off('sponsors', handleSponsors);
            socket.off('themesLanding', handleThemesLanding);
            socket.off('teamCat', handleTeamCat);
            socket.off('team', handleTeam);
            socket.off('infosBar', handleInfosBar);
            socket.off('packList', handlePackList);
            socket.off('packCat', handlePackListCat);
            socket.off('payementList', handlePayementList);
            socket.off('payementListCat', handlePayementCatList);
            socket.off('singupConfig', handleSingupConfig);
            socket.off('accountTypeChanged', handleAccountTypeChanged);
            socket.off('accountTPayementChanged', handleAccountTPayementChanged);
            socket.off('filePv', handleFilePv);
            socket.off('usersManager', handleUsersManager);
            socket.off('presenceManager', handlePresenceManager);
            socket.off('accountDesactived', handleAccountDesactived);
            socket.off('carouselChanged', handleCarouseChanged);            
        };
    }, [socket]); 

    return (
        <>
            {contextHolderNotification}
            {children}
        </>
    );
}